

const estado = {
  areaCliente: null,
  gama: '',
};

const getters = {
  areaCliente: (state) => state.areaCliente,
  gama: (state) => state.gama,
};

const actions = {
  async SetAreaCliente({ commit }, areaCliente) {
    await commit('setAreaCliente', areaCliente);
  },

  async SetGama({ commit }, gama) {
    await commit('setGama', gama);
  },

  async GetAreaCliente({ state }) {
    return state.areaCliente;
  },

  async GetGama({ state }) {
    return state.gama;
  }
};

const mutations = {
  setAreaCliente(state, areaCliente) {
    state.areaCliente = areaCliente;
  },
  setGama(state, gama) {
    state.gama = gama;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
