<template>
    <section class="section-review-password">
        <div class="wrapper_logo">
            <img class="" src="@/assets/login/logo-indo.svg" alt="indo-logo">
        </div>
        <form action="POST">
            <div class="form__title">Revisa tu bandeja de entrada</div>
            <div class="form__text">Te hemos enviado un link para recuperar la contraseña al mail loremipsum@gmail.com</div>
            <button type="submit" class="primary-button">Abrir email en App</button>
            <div class="form__resend">
                <div class="resend__text">¿No has recibido el email? </div>
                <button  class="link-button">Haz click para reenviar</button>
            </div>
            <div class="form__link">
                <img class="" src="@/assets/login/icon-arrow-full-left.svg" alt="indo-logo">
                <router-link to="/login"  class="link-return">Volver al inicio de sesión</router-link>
            </div>
        </form>
    </section>
</template>

<style lang="scss" scoped>

    section.section-review-password{
        @apply overflow-hidden max-w-[1450px] mx-auto;

        .wrapper_logo{
            @apply block self-start pt-20 pl-24;
        }

        form{
            @apply flex flex-col mt-40 mx-auto max-w-[530px] ;

            .form__title{
                @apply text-4xl font-normal text-center;
            }

            .form__text{
                @apply text-base font-normal mt-2 text-center;
            }

            .form__input{
                @apply max-w-[396px] mx-auto mt-8;


            }

            button.primary-button{
                @apply mt-8 self-center w-fit;
            }

            .form__resend{
                @apply mt-5 flex self-center;

                .resend__text{
                    @apply text-sm text-neutral font-normal;
                }
            }

            .form__link{
                @apply mt-8 flex gap-2 self-center;
            }
        }
    }
</style>
