/* eslint-disable import/no-extraneous-dependencies */
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { set, get } from 'idb-keyval';
import createIndexedDBPlugin from './plugins/indexeddb-storage';

import * as modules from './modules/index';
// import auth from './modules/auth';
// import indo from './modules/indo';
// import notifications from './modules/notifications';
// import ofertas from './modules/ofertas';
// import bisel from './modules/indobisel';
// import academy from './modules/academy';
// import misBiselados from './modules/misBiselados';

export default createStore({
  modules: {
    ...modules,
  },
  plugins: [
    // Plugin para auth usando localStorage
    createPersistedState({
      paths: ['auth', 'gama'], // Solo persistir lo que necesitamos en localStorage
      storage: window.localStorage,
    }),
    createIndexedDBPlugin([
      'indo',
      'notifications',
      'ofertas',
      'bisel',
      'academy',
      'misBiselados'
    ], 'indo-store')
  ],
});
