const estado = {
  leidas: localStorage.getItem('indo-notificaciones-leidas') || [],
};

const getters = {
  leidas: (state) => state.leidas,
  bonosNotificacionEmail: (state) => state.bonosNotificacionEmail,
};

const actions = {
  async SetLeida({ commit }, { noticiaId }) {
    commit('setNotificacionLeida', noticiaId);
  },

  ManagerSetInformacion({ rootState }, { checked }) {
    const user = rootState.auth.indoManager.CLIENTE;

    localStorage.setItem(`indo-bonos-send-information-${user}`, JSON.stringify(checked));
  },

  ManagerGetInformacion({ rootState }) {
    const user = rootState.auth.indoManager.CLIENTE;

    return JSON.parse(localStorage.getItem(`indo-bonos-send-information-${user}`)) || false;
  },

  GetNewsPopup({ rootState }) {
    const user = rootState.auth.username;
    return JSON.parse(localStorage.getItem(`indo-bonos-news-popup-show-${user}`)) || false;
  },

  SetNewsPopup({ rootState }, { checked }) {
    const user = rootState.auth.username;
    localStorage.setItem(`indo-bonos-news-popup-show-${user}`, JSON.stringify(checked));
  },
};

const mutations = {
  setNotificacionLeida(state, data) {
    if (!state.leidas.includes(data)) {
      state.leidas.push(data);
      localStorage.setItem('indo-notificaciones-leidas', state.leidas);
    }
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
