<template>
    <section class="section-remember-password">
        <div class="wrapper_logo">
            <img class="" src="@/assets/login/logo-indo.svg" alt="indo-logo">
        </div>
        <form action="POST">
            <div class="form__title">¿Has olvidado tu contraseña?</div>
            <div class="form__text">Por favor indica tu número de cliente y pulsa enviar para solicitar la nueva contraeña.</div>
            <div class="form__text">En pocos minutos recibirás un mail a tu cuenta de correo registrada con una contraseña temporal.</div>
            <div class="form__input">
                <label for="#client">Cliente*</label>
                <input
                  class="credentials"
                  type="text"
                  autocapitalize="none"
                  placeholder=""
                  autocomplete="mail"
                  id="client"
                  v-model="cliente"
                />
            </div>
            <p class="error" v-show="this.notSent">Algo no ha ido bien, código de cliente incorrecto.</p>
            <button type="submit" class="primary-button" @click.prevent="cambioPassword" :disabled="this.cliente === ''">Enviar</button>
            <div class="form__link">
                <img class="" src="@/assets/login/icon-arrow-full-left.svg" alt="indo-logo">
                <router-link to="/login" class="link-return">Volver al inicio de sesión</router-link>
            </div>
        </form>
    </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      cliente: '',
      notSent: false,
    }
  },
  methods: {
    ...mapActions(['PedirContrasena']),
    cambioPassword() {
      if (this.PedirContrasena({cliente: this.cliente})) {
        this.$router.push({name: 'login'});
      } else this.notSent = true;

    }
  },
}
</script>

<style lang="scss" scoped>

    .error {
      @apply text-red text-sm mt-8 ml-16;
    }
    section.section-remember-password{
        @apply overflow-hidden max-w-[1450px] mx-auto;

        .wrapper_logo{
            @apply block self-start pt-20 pl-24;
        }

        form{
            @apply flex flex-col mt-40 mx-auto max-w-[530px] ;

            .form__title{
                @apply text-4xl font-normal text-center;
            }

            .form__text{
                @apply text-base font-normal mt-2 text-center;
            }

            .form__input{
                @apply max-w-[396px] mx-auto mt-8;


            }

            button.primary-button{
                @apply mt-8 self-end w-fit;
            }

            .form__link{
                @apply mt-8 flex gap-2 self-center;
            }
        }
    }
</style>
