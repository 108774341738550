const formatDate = (inputDate) => {
  let date;
  let month;
  const year = inputDate.getFullYear();

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;

  date = date
        .toString()
        .padStart(2, '0');
  month = month
        .toString()
        .padStart(2, '0');

  return `${date}/${month}/${year}`;
};

export default formatDate;
