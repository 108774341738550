import { createApp } from 'vue';
import { clear } from 'idb-keyval';

import App from './App.vue';
import router from './router';
import store from './store';
import './index.scss';

// console.log(import.meta.env);

window.addEventListener('beforeunload', async (event) => {
  try {
    // await clear();
  } catch (error) {
    console.error('Error al limpiar IndexedDB:', error);
  }
});

if (import.meta.env.MODE !== 'development') {
  window.consoleHolder = window.console;
  window.console = {};
  Object.keys(window.consoleHolder).forEach((key) => {
    console[key] = () => {};
  });
}

createApp(App).use(store).use(router).mount('#app');
