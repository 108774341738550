import axios from 'axios';
import axiosConfig from '@/config/axios';

export default {
  loadInfo(cliente, token) {
    return axios.get(
      `${axiosConfig.ofertasURL}LoadInfoIndonet/${cliente}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  saveInfo(valorPantalla, cliente, token) {
    return axios.post(
      `${axiosConfig.ofertasURL}SaveInfoIndonetMonitorInfo/`,
      {
        ValorPantalla: valorPantalla,
        Cliente: cliente,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  getFotocromaticos(org, token) {
    return axios.post(
      `${axiosConfig.ofertasURL}GetFotocromaticos/`,
      {
        vkorg: org,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  get(endpoint, payload, token) {
    return axios.post(
      `${axiosConfig.ofertasURL}${endpoint}/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
};
