<template>
    <div :class="{ hidden: !show }" class="loader-overlay">
      <div class="loader"></div>
    </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      show: Boolean,
    },
  };
</script>

<style scoped>
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  .hidden {
    display: none;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
