/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import config from '@/config/indo';
import axiosConfig from '@/config/axios';
import formatDate from '@/helpers/dates';

import ofertas from './ofertas';
import indobisel from './indobisel';
import academy from './academy';
import indoscan from './indoscan';

export default {
  ofertas: { ...ofertas },
  indobisel: { ...indobisel },
  academy: { ...academy },
  indoscan: { ...indoscan },
  login(username, password) {
    return axios.post(
      axiosConfig.indonetURL,
      config.login.xml.replace('{username}', username).replace('{password}', password),
      {
        headers: axiosConfig.headers,
      },
    );
  },

  loginManager(username, password) {
    return axios.post(
      axiosConfig.indonetURL,
      config.loginManager.xml.replace('{username}', username).replace('{password}', password),
      {
        headers: axiosConfig.headers,
      },
    );
  },

  postLogin(username, token) {
    return axios.post(
      axiosConfig.indonetURL,
      config.postLogin.xml.replace('{username}', username),
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getPedidos(username, token, profile) {
    const today = new Date();
    const payload = config.pedidos.xml
      .replace('{username}', username)
      .replace('{org}', profile.AreaVenta.ORG_VENTA)
      .replace('{canal}', profile.AreaVenta.CANAL_DIST)
      .replace('{sector}', profile.AreaVenta.SECTOR)
      .replace('{fecha_ini}', formatDate(new Date(today.setDate(today.getDate() - 30)))) // remove 30 days
      .replace('{fecha_fin}', formatDate(new Date(today.setDate(today.getDate() + 60)))); // add 30 days

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getNuevoPedido(token, org, xmlAreaVenta, xmlDatosConfig) {
    const payload = config.nuevoPedido.xml;

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  saveAjustes(xmlDatosConfig, token) {
    const payload = config.ajustes.xml.replace('{datosConfig}', xmlDatosConfig);
    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  saveMailbox(xmlDatosConfig, token) {
    const payload = config.miBuzon.xml.replace('{datosConfig}', xmlDatosConfig);
    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  cambioPassword(username, password, newPassword, token) {
    const payload = config.cambioPassword.xml
        .replace('{username}', username)
        .replace('{old_pass}', password)
        .replace('{new_pass}', newPassword);
    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  searchManagerFacturas(username, token, manager, from, to, noclient = '', nofact = '', noalb = '') {
    const payload = config.buscarListaFacturas.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{solicitante}', username)
      .replace('{org}', manager.ORGANIZACION_VENTAS)
      .replace('{canal}', manager.CANAL_DISTRIBUCION)
      .replace('{sector}', manager.SECTOR)
      .replace('{fecha_inicio}', from)
      .replace('{fecha_fin}', to)
      .replace('{tipo_factura}', 'T')
      .replace('{albaran}', noalb)
      .replace('{pedido}', '')
      .replace('{referencia}', '')
      .replace('{factura}', nofact)
      .replace('{idioma}', '');

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getBonos(username, token, manager) {
    const payload = config.bonos.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{solicitante}', '')
      .replace('{org}', manager.ORGANIZACION_VENTAS)
      .replace('{canal}', manager.CANAL_DISTRIBUCION)
      .replace('{sector}', manager.SECTOR)
      .replace('{idioma}', '');

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getFacturaPdf(username, token, manager, factura, fechaFactura) {
    // const payload = config.bonos.xml
    //   .replace('{cliente}', manager.CLIENTESAP)
    //   .replace('{solicitante}', username)
    //   .replace('{factura}', factura)
    //   .replace('{fecha_factura}', fechaFactura)
    //   .replace('{idioma}', 'ES');

    return axios.get('/factura.xml');
    /* return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    ); */
  },

  getBonoDetalle(token, manager, bono) {
    const payload = config.bonoDetalle.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{bono}', bono)
      .replace('{org}', manager.ORGANIZACION_VENTAS)
      .replace('{canal}', manager.CANAL_DISTRIBUCION)
      .replace('{sector}', manager.SECTOR)
      .replace('{idioma}', '');

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getEstadisticaServicio(token, manager) {
    const payload = config.estadisticaServicio.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{org}', manager.ORGANIZACION_VENTAS)
      .replace('{mes_ini}', '1')
      .replace('{anyo_ini}', '2023')
      .replace('{mes_fin}', '7')
      .replace('{anyo_fin}', '2023')
      .replace('{idioma}', '');

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getEstadisticaConsumo(token, manager) {
    const payload = config.estadisticaConsumo.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{org}', manager.ORGANIZACION_VENTAS)
      .replace('{mes_ini}', '1')
      .replace('{anyo_ini}', '2023')
      .replace('{mes_fin}', '7')
      .replace('{anyo_fin}', '2023')
      .replace('{idioma}', '');

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  enviarEmail(token, to, subject, body) {
    const payload = config.enviarEmail.xml
      .replace('{to}', to)
      .replace('{subject}', subject)
      .replace('{body}', body);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  getBases(token, xmlPedido) {
    const payload = config.basesEspeciales.xml
      .replace('{oClaseApi}', xmlPedido);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  prepararPedido(token, xmlPedido) { // calcular
    const payload = config.prepararPedido.xml
      .replace('{oClaseApi}', xmlPedido);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  realizarPedido(token, xmlPedido) { // calcular
    const payload = config.realizarPedido.xml
      .replace('{oClaseApi}', xmlPedido);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  pedirContrasena(cliente) {
    const payload = config.pedirContrasena.xml
      .replace('{cliente}', cliente)
      .replace('{env_usuario}', import.meta.env.VITE_RECUPERAR_PWD_USUARIO)
      .replace('{env_pwd}', import.meta.env.VITE_RECUPERAR_PWD_PASSWORD)
      .replace('{env_app}', import.meta.env.VITE_RECUPERAR_APLICACION);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
        },
      },
    );
  },

  cambiarContrasena(token, cliente, actual, nueva) {
    const payload = config.cambiarContrasena.xml
      .replace('{cliente}', cliente)
      .replace('{actual}', actual)
      .replace('{nueva}', nueva);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  monturas(token, manager) {
    const payload = config.monturas.xml
      .replace('{cliente}', manager.CLIENTESAP)
      .replace('{org}', manager.ORG_VENTA)
      .replace('{canal}', manager.CANAL_DIST)
      .replace('{sector}', manager.SECTOR);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  guardarForma(token, xmlForma) {
    const payload = config.guardarForma.xml
      .replace('{oClaseApi}', xmlForma);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },

  buscarForma(token, cliente) {
    const payload = config.buscarForma.xml
      .replace('{cliente}', cliente);

    return axios.post(
      axiosConfig.indonetURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    );
  },
};
