export default {
  indonetURL: import.meta.env.VITE_APP_INDONET_URL,
  indoscanURL: import.meta.env.VITE_APP_INDOSCAN_URL,
  moodleRestURL: import.meta.env.VITE_APP_MOODLE_REST_URL,
  moodleURL: import.meta.env.VITE_APP_MOODLE_URL,
  ofertasURL: import.meta.env.VITE_APP_OFERTAS_URL,
  indobiselURL: import.meta.env.VITE_APP_BISELADO_URL,
  moodleToken: import.meta.env.VITE_APP_MOODLE_TOKEN,
  headers: {
    'Content-Type': 'text/xml; charset=utf-8',
    Accept: 'text/xml; charset=utf-8',
  },
  headersOfertas: {
    Accept: 'text/json; charset=utf-8',
  },
};
