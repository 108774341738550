import { get, set } from 'idb-keyval';

const createIndexedDBPlugin = (paths = [], key = 'vuex') => {
  const storage = {
    async getItem(key) {
      try {
        const value = await get(key);
        return value;
      } catch (error) {
        console.error('Error en getItem:', error);
        return null;
      }
    },

    async setItem(key, value) {
      try {
        await set(key, value);
      } catch (error) {
        console.error('Error en setItem:', error);
      }
    }
  };

  return (store) => {
    // Cargar estado inicial
    storage.getItem(key).then(value => {
      if (value) {
        const state = JSON.parse(value);
        const newState = { ...store.state };
        paths.forEach(path => {
          if (state[path]) {
            newState[path] = state[path];
          }
        });
        store.replaceState(newState);
      }
    });

    // Guardar cambios
    store.subscribe((mutation, state) => {
      const stateToPersist = paths.reduce((acc, path) => {
        acc[path] = state[path];
        return acc;
      }, {});

      storage.setItem(key, JSON.stringify(stateToPersist));
    });
  };
};

export default createIndexedDBPlugin;
