<template>
    <Loader :show="showLoader" />
    <section class="section-new-password">
        <div class="wrapper_logo">
            <img class="" src="@/assets/login/logo-indo.svg" alt="indo-logo">
        </div>
        <form action="POST">
            <div class="form__title">Nueva contraseña</div>
            <div class="form__text">Introduce en clave actual la clave que has recibido al correo electrónico</div>
            <!-- <div class="form__input">
                <label for="#mail">Email*</label>
                <input
                class="credentials"
                type="mail"
                autocapitalize="none"
                placeholder="Escribe aquí tu email"
                autocomplete="mail"
                id="mail"
                />
            </div> -->
            <div class="form-field password">
              <div class="input__title">Clave actual*</div>
              <div class="input__wrapper">
                <input
                  class="credentials"
                  type="password"
                  id="temporal_password"
                  v-model="actualC"
                />
              </div>
            </div>
            <div class="form-field password">
              <div class="input__title">Nueva clave*</div>
              <div class="input__wrapper">
                <input
                  class="credentials"
                  type="password"
                  id="new_password"
                  v-model="nuevaC"
                />
              </div>
            </div>
            <div class="form-field password">
              <div class="input__title">Confirmar clave*</div>
              <div class="repeat_password">
                <input
                  class="credentials"
                  type="password"
                  id="id_password"
                  v-model="repetirC"
                />
              </div>
            </div>
            <p class="error" v-show="this.error">{{ this.errorMsg }}</p>
            <button type="submit" class="primary-button" @click.prevent="send">Iniciar sesión</button>
        </form>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/atoms/Loader.vue';

export default {
  components: { Loader },
  data() {
    return {
      error: false,
      errorMsg: '',
      nuevaC: '',
      actualC: '',
      repetirC: '',
      showLoader: false,
    }
  },
  computed: {
      ...mapGetters(['username', 'tempPassword']),
  },
  methods: {
    ...mapActions(['CambiarContrasena']),
    GetTokenTempPassword(cliente) {
      let token = '*';
      let codigo = cliente.replace(/^0+/, '');
      token += codigo.charAt(0);
      token += codigo.charAt(codigo.length - 1);

      const now = new Date();
      const actualHour = now.getHours();
      const actualMonth = now.getMonth() + 1;
      now.setDate(now.getDate() - 1);
      const yesterday = now.getDate();

      const num = Math.abs(actualHour - yesterday + actualMonth);
      return `${token}${num}`;

// Private Function GetTokenTempPassw(sCodigo As String) As String
// Dim sToken As String = "*"
// Do While sCodigo.Substring(0, 1) = "0"
//     sCodigo = sCodigo.Substring(1, sCodigo.Length - 1)
// Loop
// sToken = sToken & sCodigo.Substring(0, 1)
// sToken = sToken & sCodigo.Substring(sCodigo.Length - 1, 1)
// Dim iNumero As Integer = Val(Format(Now, "HH"))
// iNumero = iNumero - Val(Format(DateAdd(DateInterval.Day, -1, Now), "dd"))
// iNumero = iNumero + Val(Format(Now, "MM"))
// iNumero = Math.Abs(iNumero)
// sToken = sToken + iNumero.ToString
// Return sToken
// End Function


    },
    async send() {
      this.showLoader = true;
      if (this.nuevaC !== this.repetirC) {
        // errror
        this.errorMsg = 'Las contraseñas no son iguales';
        this.error = true;
        return false;
      }

      if (this.actualC !== this.tempPassword) {
        // errror
        this.errorMsg = 'Las clave actual no es la que se ha mandado al correo electronico.';
        this.error = true;
        return false;
      }

      const temp = this.GetTokenTempPassword(this.username);

      const np = await this.CambiarContrasena({
        actual: temp,
        nueva: this.nuevaC,
        cliente: this.username,
      });

      if (np === true) {
        this.$router.push('/dashboard');
      } else {
        this.errorMsg = np.MensajeErrorSAP;
        this.error = true;
      }
      this.showLoader = false;
    }
  },
}
</script>

<style lang="scss" scoped>

  .error {
    @apply text-red text-sm my-10 ml-8;
  }
    section.section-new-password{
        @apply overflow-hidden max-w-[1450px] mx-auto;

        .wrapper_logo{
            @apply block self-start pt-20 pl-24;
        }

        form{
            @apply flex flex-col mt-40 mx-auto max-w-[530px] ;

            .form__title{
                @apply text-4xl font-normal text-center;
            }

            .form__text{
                @apply text-base font-normal mt-2 text-center;
            }

            .form__input{
                @apply max-w-[396px] mx-auto mt-8;


            }

            .password{
                @apply max-w-[396px] mx-auto mt-8;
            }

            button.primary-button{
                @apply mt-8 self-end w-fit;
            }

            .form__link{
                @apply mt-8 flex gap-2 self-center;
            }
        }
    }
</style>
