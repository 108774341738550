export default {
  login: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <wcfAutenticacion__Execute xmlns="http://indo.es/WSIndonetSOAP">
          <sClienteSAP>{username}</sClienteSAP>
          <sPasswordSAP>{password}</sPasswordSAP>
        </wcfAutenticacion__Execute>
      </soap12:Body>
    </soap12:Envelope>`,
    methods: {
      response: 'wcfAutenticacion__ExecuteResult',
    },
  },
  postLogin: {
    xml: `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
    <soapenv:Body>
       <wcfObtenerDatosClienteIntegrado_Execute>
          <AreaVenta>
             <CLIENTE>{username}</CLIENTE>
             <OPCION></OPCION>
          </AreaVenta>
       </wcfObtenerDatosClienteIntegrado_Execute>
    </soapenv:Body>
    </soapenv:Envelope>`,
    methods: {
      response: 'wcfObtenerDatosClienteIntegrado_ExecuteResult',
    },
  },
  pedidos: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <wcfBuscarPedido_Execute xmlns="http://indo.es/WSIndonetSOAP">
             <oClaseBAPI>
                <KUNNR>{username}</KUNNR>
                <VKORG>{org}</VKORG>
                <VTWEG>{canal}</VTWEG>
                <SPART>{sector}</SPART>
                <FECHA_REC_LOW>{fecha_ini}</FECHA_REC_LOW>
                <FECHA_REC_HI>{fecha_fin}</FECHA_REC_HI>
                <GRABADO>T</GRABADO>
             </oClaseBAPI>
          </wcfBuscarPedido_Execute>
      </soap12:Body>
    </soap12:Envelope>`,
    methods: {
      response: 'wcfBuscarPedido_ExecuteResult',
    },
  },
  ajustes: {
    xml: `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
        <soapenv:Body>
          <wcfGuardarDatosOptica_Execute>
              {datosConfig}
          </wcfGuardarDatosOptica_Execute>
        </soapenv:Body>
    </soapenv:Envelope>`,
    methods: {
      response: 'wcfGuardarDatosOptica_ExecuteResult',
    },
  },
  cambioPassword: {
    xml: `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
            <soapenv:Body>
              <wcfCambiarPassword_Execute>
                <sClienteSAP>{username}</sClienteSAP>
                <sPasswordSAP>{old_pass}</sPasswordSAP>
                <sNuevaPasswordSAP>{new_pass}</sNuevaPasswordSAP>
              </wcfCambiarPassword_Execute>
            </soapenv:Body>
          </soapenv:Envelope>`,
    methods: {
      response: 'wcfCambiarPassword_ExecuteResult',
    },
  },

  loginManager: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <wcfAutenticacion__ExecuteChequeaPasswordIM xmlns="http://indo.es/WSIndonetSOAP">
          <sCliente>{username}</sCliente>
          <sPassword>{password}</sPassword>
        </wcfAutenticacion__ExecuteChequeaPasswordIM>
      </soap12:Body>
    </soap12:Envelope>`,
    methods: {
      response: 'wcfAutenticacion__ExecuteChequeaPasswordIMResult',
    },
  },
  buscarListaFacturas: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <BuscarListaFacturas>
          <cliente_manager>{cliente}</cliente_manager>
          <solicitante>{solicitante}</solicitante>
          <org_ventas>{org}</org_ventas>
          <canal>{canal}</canal>
          <sector>{sector}</sector>
          <fecha_inicio>{fecha_inicio}</fecha_inicio>
          <fecha_fin>{fecha_fin}</fecha_fin>
          <tipo_factura>{tipo_factura}</tipo_factura>
          <albaran>{albaran}</albaran>
          <pedido>{pedido}</pedido>
          <referencia>{referencia}</referencia>
          <factura>{factura}</factura>
          <sIdioma>{idioma}</sIdioma>
        </BuscarListaFacturas>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'BuscarListaFacturasResult',
    },
  },
  bonos: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <BuscarListaBonosCliDesdeIM>
          <cliente_manager>{cliente}</cliente_manager>
          <cliente>{solicitante}</cliente>
          <org_ventas>{org}</org_ventas>
          <canal>{canal}</canal>
          <sector>{sector}</sector>
          <sIdioma>{idioma}</sIdioma>
        </BuscarListaBonosCliDesdeIM>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'BuscarListaBonosCliDesdeIMResult',
    },
  },
  facturaSap: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <RecuperarFacturaDeSAP>
          <pagador>{cliente}</pagador>
          <solicitante>{solicitante}</solicitante>
          <sNumero>{factura}</sNumero>
          <sFechaFra>{fecha_factura}</sFechaFra>
          <sIdioma>{idioma}</sIdioma>
        </RecuperarFacturaD3eSAP>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'RecuperarFacturaDeSAPResult',
    },
  },
  bonoDetalle: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <BuscarListaDetallePuntosBonos xmlns="http://indo.es/WSIndonetSOAP">
          <cliente_manager>{cliente}</cliente_manager>
          <bono>{bono}</bono>
          <org_ventas>{org}</org_ventas>
          <canal>{canal}</canal>
          <sector>{sector}></sector>
          <sIdioma>{idioma}</sIdioma>
        </BuscarListaDetallePuntosBonos>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'BuscarListaDetallePuntosBonosResult',
    },
  },
  estadisticaServicio: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <BuscarEstadisticasServicio>
          <cliente_manager>{cliente}</cliente_manager>
          <org_ventas>{org}</org_ventas>
          <mes_inicial>{mes_ini}</mes_inicial>
          <anyo_inicial>{anyo_ini}</anyo_inicial>
          <mes_final>{mes_fin}</mes_final>
          <anyo_final>{anyo_fin}</anyo_final>
          <sIdioma>{idioma}</sIdioma>
        </BuscarEstadisticasServicio>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'BuscarEstadisticasServicioResult',
    },
  },
  estadisticaConsumo: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <BuscarEstadisticasConsumos>
          <cliente_manager>{cliente}</cliente_manager>
          <org_ventas>{org}</org_ventas>
          <mes_inicial>{mes_ini}</mes_inicial>
          <anyo_inicial>{anyo_ini}</anyo_inicial>
          <mes_final>{mes_fin}</mes_final>
          <anyo_final>{anyo_fin}</anyo_final>
          <sIdioma>{idioma}</sIdioma>
        </BuscarEstadisticasConsumos>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'BuscarEstadisticasConsumosResult',
    },
  },
  enviarEmail: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <EnviarEmail>
          <EmailTo>{to}</EmailTo>
          <Subject>{subject}</Subject>
          <Body>{body}</Body>
        </EnviarEmail>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'EnviarEmailResult',
    },
  },
  miBuzon: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <wcfGuardarMailMiBuzon_Execute>
          {datosConfig}
        </wcfGuardarMailMiBuzon_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfGuardarMailMiBuzon_ExecuteResult',
    },
  },
  nuevoPedido: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <NuevoPedido>

        </NuevoPedido>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'NuevoPedidoResult',
    },
  },
  basesEspeciales: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
          <wcfRecuperarBases_Execute>
              <oClaseBAPI>
                {oClaseApi}
              </oClaseBAPI>
              <MensajeErrorSAP></MensajeErrorSAP>
          </wcfRecuperarBases_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfRecuperarBases_ExecuteResult',
    },
  },
  prepararPedido: { // aixo ha de ser calcular
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <wcfActualizarPedido_Execute xmlns="http://indo.es/WSIndonetSOAP">
          <oClaseBAPI>
            {oClaseApi}
          </oClaseBAPI>
        </wcfActualizarPedido_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfActualizarPedido_ExecuteResult',
    },
  },
  realizarPedido: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <wcfEnviarPedido_Execute xmlns="http://indo.es/WSIndonetSOAP">
          <oClaseBAPI>
            {oClaseApi}
          </oClaseBAPI>
        </wcfEnviarPedido_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfEnviarPedido_ExecuteResult',
    },
  },
  pedirContrasena: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <wcfAutenticacion_ExecuteCreaPwdTemporal>
          <sUsuario>{env_usuario}</sUsuario>
          <sPwd>{env_pwd}</sPwd>
          <sClienteSAP>{cliente}</sClienteSAP>
          <sIP></sIP>
          <sAplicacion>{env_app}</sAplicacion>
        </wcfAutenticacion_ExecuteCreaPwdTemporal>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfAutenticacion_ExecuteCreaPwdTemporalResult',
    }
  },
  cambiarContrasena: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <wcfCambiarPassword_Execute>
            <sClienteSAP>{cliente}</sClienteSAP>
            <sPasswordSAP>{actual}</sPasswordSAP>
            <sNuevaPasswordSAP>{nueva}</sNuevaPasswordSAP>
        </wcfCambiarPassword_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfCambiarPassword_ExecuteResult',
    }
  },

  monturas: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
        <wcfRecuperarMonturas_Execute>
          <oClaseBAPI>
            <CLIENTE>{cliente}</CLIENTE>
            <ORG_VENTA>{org}</ORG_VENTA>
            <CANAL_DIST>{canal}</CANAL_DIST>
            <SECTOR>{sector}</SECTOR>
            <TIPO_MONTURA></TIPO_MONTURA>
          </oClaseBAPI>
        </wcfRecuperarMonturas_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfRecuperarMonturas_ExecuteResult',
    }
  },
  guardarForma: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
      <soap:Body>
        <wcfGuardarForma_Execute xmlns="http://indo.es/WSIndonetSOAP">
          <oClaseBAPI>
            {oClaseApi}
          </oClaseBAPI>
        <MensajeErrorSAP>string</MensajeErrorSAP>
      </wcfGuardarForma_Execute>
    </soap:Body>
  </soap:Envelope>`,
    methods: {
      response: 'wcfGuardarForma_ExecuteResult',
    }
  },
  buscarForma: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://indo.es/WSIndonetSOAP">
      <soap:Body>
          <wcfBuscarFormas_Execute>
              <oClaseBAPI>
                  <CLIENTE>{cliente}</CLIENTE>
              </oClaseBAPI>
          </wcfBuscarFormas_Execute>
      </soap:Body>
    </soap:Envelope>`,
    methods: {
      response: 'wcfBuscarFormas_ExecuteResult',
    }
  },
  getFormaFromImagen: {
    xml: `<?xml version="1.0" encoding="utf-8"?>
    <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
      <soap12:Body>
        <GetFormaFromImagen xmlns="http://tempuri.org/">
          <imagen>{imagen}</imagen>
          <otrosDatos>{datos}</otrosDatos>
        </GetFormaFromImagen>
      </soap12:Body>
    </soap12:Envelope>`,
    methods: {
      response: 'GetFormaFromImagenResult',
    }
  },

};
