import requests from '@/services/requests';

const estado = {
  profile2: null,
  tratamiento: null,
  indice: null,
};

const getters = {
  profile2: (state) => state.profile,
  tratamiento: (state)  => state.tratamiento,
  indice: (state)  => state.indice,
};

const actions = {
  async LoadInfo({ rootState }) {
    const data = await requests.ofertas.loadInfo(rootState.auth.username, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async SaveInfo({ rootState }, { datos }) {
    const data = await requests.ofertas.saveInfo(datos.VALOR_PANTALLA, rootState.auth.username, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return true;
  },

  async GetFotocromaticos({ rootState }, { org }) {
    const data = await requests.ofertas.getFotocromaticos(org, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetColoracion({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetColoracion', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetColor({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetColor', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetFocoyMaterial({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetFocoyMaterial', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetIndices({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetIndices', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetTratamientos({ commit, rootState }, { payload }) {
    const data = await requests.ofertas.get('GetTratamientos', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetTonos({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetTonos', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetLentes({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetLentes', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetDiametros({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetDiametros', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetDiametrosEspeciales({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetDiametrosEspeciales', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetCaracteristicasLente({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetCaracteristicasLente', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetDatosAdicionales({ rootState }, { payload }) {
    const data = await requests.ofertas.get('GetDatosAdicionales', payload, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

};

const mutations = {
  setTratamiento(state, data) {
    state.tratamiento = data;
  },
  setIndice(state, data) {
    state.indice = data;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
