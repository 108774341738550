import requests from '@/services/requests';
import response from '@/services/response';

import { clear } from 'idb-keyval';


const estado = {
  username: null,
  token: null,
  tokenManager: null,
  isAuthenticated: false,
  isAuthManager: false,
  indoManager: null,
  tempPassword: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  isAuthManager: (state) => !!state.tokenManager,
  token: (state) => state.token,
  tokenManager: (state) => state.tokenManager,
  username: (state) => state.username,
  manager: (state) => state.indoManager,
  tempPassword: (state) => state.tempPassword,
};

const actions = {
  async Login({ commit }, { username, password }) {
    const data = await requests.login(username, password);
    const authData = response.login(data);
    if (authData.returnValue === 0) {
      // Limpiar IndexedDB antes de establecer los nuevos datos
      await clear();

      await commit('setToken', authData.Token);
      await commit('setUsername', username);

      if (authData.CodigoRetorno === 20) {
        await commit('setTempPassword', password);
        return 20;
      }
    } else {
      throw new Error(authData.MensajeErrorSAP);
    }
  },

  async Logout({ commit }) {
    await commit('setToken', null);
    await commit('setUsername', null);
    await commit('emptyData', null);
    // Limpiar IndexedDB
    await clear();
  },

  async CambioPassword({ token }, { username, password, newPassword }) {
    console.log(username, password, newPassword);
    const data = await requests.cambioPassword(username, password, newPassword, token);
    const authData = response.cambioPassword(data);

    if (authData.returnValue !== 0) {
      // throw new Error(authData.MensajeErrorSAP);
      return authData.MensajeErrorSAP;
    }
    return null;
  },

  async LoginManager({ commit }, { username, password }) {
    const data = await requests.loginManager(username, password);
    const authData = response.loginManager(data);

    if (authData.returnValue === 0) {
      await commit('setTokenManager', authData.Token);
      await commit('setManager', authData.oDatosCliente);
    } else {
      throw new Error(authData.ExportReturnMessage || authData.oDatosCliente.sMensajeSAP);
    }
  },

  async ManagerLogout({ commit }) {
    await commit('setTokenManager', null);
    await commit('setManager', null);
  },

  async PedirContrasena(_, { cliente }) {
    const data = await requests.pedirContrasena(cliente);
    const authData = response.pedirContrasena(data);

    if (authData.returnValue === 0) {
      return true;
    }

    return false;
  },

  async CambiarContrasena({ state }, { cliente, actual, nueva }) {
    const data = await requests.cambiarContrasena(state.token, cliente, actual, nueva);
    const authData = response.cambiarContrasena(data);

    console.log(authData);
    if (authData.returnValue === 0) {
      return true;
    }

    return authData;
  }
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },

  setTokenManager(state, token) {
    state.tokenManager = token;
  },

  setUsername(state, username) {
    state.username = username;
  },

  setManager(state, manager) {
    state.indoManager = manager;
  },

  setTempPassword(state, tempPass) {
    state.tempPassword = tempPass;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
