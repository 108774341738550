import axios from 'axios';
import axiosConfig from '@/config/axios';

export default {
  ultimaLectura(cliente, token = null) {
    return axios.get(
      `${axiosConfig.indobiselURL}GetLastLectura/${cliente}`,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  todasLecturas(cliente, token = null) {
    return axios.get(
      `${axiosConfig.indobiselURL}GetListLecturas/${cliente}`,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  getLectura(cliente, job, token = null) {
    return axios.get(
      `${axiosConfig.indobiselURL}GetLectura/${job}/${cliente}`,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  sendISO(cliente, fileName, iso, token = null) {
    return axios.post(
      `${axiosConfig.indobiselURL}ImportarLectura/`,
      {
        fileName,
        content: iso,
        cliente,
      },
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
};
