import requests from '@/services/requests';

const estado = {

};

const getters = {

};

const actions = {
  async SendImagen({ commit }, { imagen, datos }) {
    const data = await requests.indoscan.formaFromImagen(imagen, datos);

    console.log(data);

    return data;
  },
};

const mutations = {

};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
