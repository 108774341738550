import axios from 'axios';
import axiosConfig from '@/config/axios';
import config from '@/config/indo';

export default {
  formaFromImagen(imagen, datos = '', token = null) {
    const payload = config.getFormaFromImagen.xml
      .replace('{imagen}', imagen)
      .replace('{datos}', new XMLSerializer().serializeToString(document.createTextNode(datos)));

    return axios.post(
      axiosConfig.indoscanURL,
      payload,
      {
        headers: {
          ...axiosConfig.headers,
          // Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        },
      },
    );
  },

};
