import axios from 'axios';
import axiosConfig from '@/config/axios';
// import data from './cursos.json';

export default {
  cursos() {
    // return data;
    return axios.get(
      `${axiosConfig.moodleRestURL}?wstoken=${axiosConfig.moodleToken}&wsfunction=core_course_get_courses&moodlewsrestformat=json`,
      {
        headers: {
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
  curso(curso_id) {
    // return data;
    return axios.get(
      `${axiosConfig.moodleRestURL}?wstoken=${axiosConfig.moodleToken}&wsfunction=core_course_get_courses_by_field&moodlewsrestformat=json&field=id&value=${curso_id}`,
      {
        headers: {
          ...axiosConfig.headersOfertas,
        },
      },
    );
  },
};
