import requests from '@/services/requests';
import response from '@/services/response';

const estado = {
  ultima: null,
  marcas: null,
  monturas: null,
  modelos: null,
  biseles: null,
  orientaciones: null,
  tipoMontura: null,
};

const getters = {
  ultima: (state) => state.ultima,
  monturas: (state) => state.monturas,
  marcas: (state) => state.marcas,
  modelos: (state) => state.modelos,
  biseles: (state) => state.biseles,
  orientaciones: (state) => state.orientaciones,
  tipoMontura: (state) => state.tipoMontura,
};

const actions = {
  async GetLastLectura({ rootState }) {
    const data = await requests.indobisel.ultimaLectura(rootState.auth.username, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },
  async GetAllLecturas({ rootState }) {
    const data = await requests.indobisel.todasLecturas(rootState.auth.username, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },
  async GetJob({ rootState }, { job }) {
    const data = await requests.indobisel.getLectura(rootState.auth.username, job, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },
  async sendISO({ rootState }, { fileName, iso }) {
    const data = await requests.indobisel.sendISO(rootState.auth.username, fileName, iso, rootState.auth.token);

    if (data && data.status !== 200) {
      return false;
    }

    return data.data;
  },

  async GetMontura({ commit, rootState }) {
    const data = await requests.monturas(rootState.auth.token, rootState.indo.profile.AreaVenta);
    let postData = response.monturas(data);
    console.log(postData);
    if (postData.returnValue !== 0) {
      throw new Error(postData.MensajeErrorSAP);
    }

    const sortedMarcas = postData.oClaseBAPI.listMARCAS.sort((a, b) => a.MARCA.localeCompare(b.MARCA));
    await commit('setMonturas', sortedMarcas);

    return postData.oClaseBAPI.listMARCAS;
  },

  GetMarcas({ state }) {
    return state.marcas;
  },
  GetModelo({ state }, { marca, modelo }) {
    return state.monturas.find(m => m.MARCA === marca && m.MODELO === modelo);
  },
  GetModelos({ state }, { marca }) {
    return state.modelos(marca);
  },
  GetBisel({ state }, { marca, modelo }) {
    return state.biseles(marca, modelo);
  },
  ListaBisel({ state }, { marca, modelo, calibre, montura, origenDatos }) {
    let l = [];
    try {
      if (origenDatos === "S") {
        if (montura === "A" || montura === "M") {
          l.push({ value: "10", text: 'Bisel normal' });
          l.push({ value: "11", text: 'Minibisel' });
          if (montura === "A") {
            l.push({ value: "12", text: 'Bisel seguridad' });
            l.push({ value: "13", text: 'Bisel solar' });
          }
        } else if (montura === "N") {
          l.push({ value: "05", text: 'Ranurada Metal' });
          l.push({ value: "06", text: 'Ranurada Nylon' });
        } else if (montura === "I") {
          l.push({ value: "03", text: 'Plano' });
        }
      } else if (origenDatos === "M" || origenDatos === "G") {
        let biseles = [];
        const listaMonturas = origenDatos === "M" ? state.monturas : state.listaMarcaMonturasSegundaGafa;

        if (calibre) {
          biseles = [...new Set(listaMonturas
            .filter((m) => m.MODELO === modelo && m.MARCA === marca && m.CALIBRE === calibre)
            .map((m) => origenDatos === "M" ? m.BISEL : m.TIPO_BISEL))];
        } else {
          biseles = [...new Set(listaMonturas
            .filter(m => m.MODELO === modelo && m.MARCA === marca)
            .map((m) => origenDatos === "M" ? m.BISEL : m.TIPO_BISEL))];
        }

        biseles.forEach(bisel => {
          if (bisel === "03") l.push({ value: "03", text: 'Plano' });
          if (bisel === "05") l.push({ value: "05", text: 'Ranurada Metal' });
          if (bisel === "06") l.push({ value: "06", text: 'Ranurada Nylon' });
          if (bisel === "10") l.push({ value: "10", text: 'Bisel normal' });
          if (bisel === "11") l.push({ value: "11", text: 'Minibisel' });
        });
      } else {
        if (montura === "A" || montura === "M") {
          l.push({ value: "10", text: 'Bisel normal' });
          l.push({ value: "11", text: 'Minibisel' });
          if (montura === "A") {
            l.push({ value: "12", text: 'Bisel seguridad' });
            l.push({ value: "13", text: 'Bisel solar' });
          }
        } else if (montura === "N") {
          l.push({ value: "05", text: 'Ranurada Metal' });
          l.push({ value: "06", text: 'Ranurada Nylon' });
        } else if (montura === "I") {
          l.push({ value: "03", text: 'Plano' });
        }
      }

      // if (l.length > 0) {
      //   l.unshift({ value: "", text: 'Selecciona' });
      // }
      // console.log(l);
      return l;
    } catch (ex) {
      console.error("Error en filterListaBisel:", ex);
      return l;
    }
  },
  GetOrientacionesByBisel({ }, { bisel }) {
    const orientaciones = [];

    const addOrientacion = (value, text) => orientaciones.push({ value, text });

    try {
      if (bisel !== "03") {
        addOrientacion("01", 'Frontal');

        if (bisel !== "12" && bisel !== "13") {
          addOrientacion("02", 'Automático (1/3-2/3)');
          addOrientacion("03", 'Centrado (1/2-1/2)');
        }
      }
      // else if (orientaciones.length === 0) {
      //   addOrientacion("03", 'Centrado (1/2-1/2)');
      // }

      return orientaciones;
    } catch (error) {
      console.error("Error en filterOrientacion:", error);
      return orientaciones;
    }
  },
  GetTipoMontura({ state }, { marca, modelo }) {
    return state.tipoMontura(marca, modelo);
  },
  ListTipoMontura() {
    return [
      { value: 'I', text: 'Taladrada' },
      { value: 'N', text: 'Ranurada' },
      { value: 'A', text: 'Acetato' },
      { value: 'M', text: 'Metal' }
    ];
  },
  GetSelectedMontura({ state }, { marca, modelo, tipoMontura = null, bisel = null, orientacion = null }) {
    let data = state.monturas.filter(m => m.MARCA === marca && m.MODELO === modelo);

    if (tipoMontura) {
      data = data.filter(m => m.TIPO_MONTURA === tipoMontura);
    }
    if (bisel) {
      data = data.filter(m => m.BISEL === bisel);
    }
    if (orientacion) {
      data = data.filter(m => m.ORIENTACION === orientacion);
    }

    return data;
  },
};

const mutations = {
  setUltimaLectura(state, data) {
    state.ultima = data;
  },
  setMonturas(state, data) {
    state.monturas = data;

    state.marcas = [...new Set(data.map(montura => montura.MARCA))];
    state.modelos = (marca) => [...new Set(data
                          .filter(montura => montura.MARCA === marca)
                          .map(montura => montura.MODELO))
                        ];
    state.biseles = (marca, modelo) => [...new Set(data
                          .filter(montura => montura.MARCA === marca && montura.MODELO == modelo)
                          .map(montura => montura.BISEL))
                        ];
    state.orientaciones = (marca, modelo) => [...new Set(data
                          .filter(montura => montura.MARCA === marca && montura.MODELO == modelo)
                          .map(montura => montura.ORIENTACION))
                        ];
    state.tipoMontura = (marca, modelo) => [...new Set(state.monturas
                          .filter(montura => montura.MARCA === marca && montura.MODELO == modelo)
                          .map(montura => montura.TIPO_MONTURA))
                        ];
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
