<template>
    <ReviewPassword />
</template>

<script>
  import ReviewPassword from '@/components/sections/password/ReviewPassword.vue';

  export default {
    name: 'ReviewPasswordView',
    components: {
        ReviewPassword,
  },
  };
</script>
