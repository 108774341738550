import requests from '@/services/requests';

const estado = {

};

const getters = {
  cursos: (state) => state.cursos,
};

const actions = {
  async GetCursos({ commit }) {
    const data = await requests.academy.cursos();

    // filter by visible and start_date after today
    const cursos = data
                      .filter((curso) => curso.visible !== 0)
                      .sort((a, b) => b.startdate - a.startdate)
                      .map(async (c) => {
                        const curso = await this.GetCurso(c.id);
                        return { ...c, ...curso };
                      });

    // cursos.forEach((curso) => {
    //   console.log(new Date(curso.startdate * 1000));
    // });
    await commit('setCursos', cursos);

    return cursos;
  },
  async GetCurso({ commit }, { curso_id }) {
    const data = await requests.academy.curso(curso_id);

    // filter by visible and start_date after today
    // const cursos = data.filter((curso) => curso.visible && curso.startdate * 1000 > new Date().getTime());
    const cursos = data.filter((curso) => curso.visible !== 0).sort((a, b) => b.startdate - a.startdate);

    // cursos.forEach((curso) => {
    //   console.log(new Date(curso.startdate * 1000));
    // });
    await commit('setCursos', cursos);

    return cursos;
  },
};

const mutations = {
  setCursos(state, data) {
    state.cursos = data;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
