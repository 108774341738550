<template>
    <NewPassword />
</template>
  
  <script>
  import NewPassword from '@/components/sections/password/NewPassword.vue';

  
  export default {
    name: 'NewPasswordView',
    components: {
      NewPassword,
  },
  };
  </script>
  