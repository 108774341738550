<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <Loader :show="showLoader" />
  <section class="section-login">
    <div class="firstForm">
      <div class="login-section">
        <div class="login-wrapper">
          <a href="https://indoonestop.jellibylab.com/"><img class="wrapper__logo" src="../assets/login/logo-indo.svg" alt="indo-logo"></a>
          <h2 class="title">
            Inicia sesión con tu cuenta
          </h2>
          <p class="text">Bienvenido/a, para empezar inicia sesión con tu cuenta</p>
          <form method="post" @submit.prevent="validate">
            <label for="#username">Usuario*</label>
            <input
                class="credentials"
                type="text"
                autocapitalize="none"
                placeholder="Escribe aquí tu usuario"
                autocomplete="username"
                required=""
                id="username"
                v-model="username"
            />
            <div class="form-field password">
              <div class="input__title">Contraseña*</div>
              <div class="input__wrapper">
                <input
                  class="credentials"
                  ref="passwordInput"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  id="id_password"
                  v-model="password"
                />
                <div @click="togglePassword" class="wrapper-svg" id="togglePassword">
                  <img src="../assets/login/icon-eye-psw.svg" alt="">
                </div>
                <p v-if="error" class="error">
                  {{ this.errorMessage }}
                </p>
              </div>
            </div>
            <div class="checkbox">
              <div class="remember-check">
                <input type="checkbox" id="remember" value="remember" v-model="remember" />
                <label for="#coding">Recordarme</label>
              </div>
              <router-link to="/remember-password">
                  Has olvidado tu contraseña?
              </router-link>
            </div>
            <button type="submit" class="primary-button">Iniciar sesión</button>
          </form>
        </div>
      </div>
    </div>
    <aside class="info-section">
        <div class="title">Nueva plataforma para profesionales</div>
        <div class="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        <a href=" http://indoonestop.jellibylab.com/solicitar-informacion" target="" class="secundary-button">Solicitar información</a>
    </aside>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue';
// import auth from '@/services/requests';
import Loader from '@/components/atoms/Loader.vue';
import { mapActions } from 'vuex';

export default {
    name: 'LoginView',
    components: { Loader },
    data: () => ({
        username: '',
        password: '',
        remember: false,
        error: false,
        errorMessage: '',
        showLoader: false,
    }),
    methods: {
        ...mapActions(['Login', 'PostLogin']),
        async validate() {
          this.showLoader = true;
            try {
                const ret = await this.Login({ username: this.username, password: this.password });
                await this.PostLogin();
                if (ret === 20) {
                  this.$router.push('/new-password');
                } else {
                  this.$router.push('/dashboard');
                }
            } catch (error) {
                this.error = true;
                console.log(error);
                this.errorMessage = error;
                this.showLoader = false;
            }
        },
        togglePassword() {
            const { passwordInput } = this.$refs;
            const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
            passwordInput.setAttribute('type', type);
        },
    },
};

</script>

<style lang="scss" scoped>
  .section-login {
    @apply overflow-hidden relative flex flex-col lg:flex-row;

    .firstForm {
      @apply w-full lg:w-[53%] h-[100vh] flex items-center;
    }

    .login-wrapper {
      @apply mx-[14px] lg:mx-10 xl:mx-40 flex flex-col items-center;

      .wrapper__logo{
        @apply inline-block self-start;
      }
      .text{
        @apply text-base pt-[11px] md:pt-[9px] font-normal text-neutral self-start text-center md:text-left;
      }

      form{
        @apply flex flex-col self-start pt-[30px] w-[396px] text-neutral;

        [for="#username"] {
          @apply text-left self-start mt-8;
        }

        #username{
          @apply mb-[30px];
        }


        .checkbox{
          @apply mt-[25px] flex self-start relative text-xs w-full justify-between;

          [type="checkbox"]{
            @apply w-4;
          }

          a{
            place-self: end;
          }

          button{
            @apply text-xs underline text-primary hover:text-primary_dark ml-[120px] self-end;
          }
        }

        button[type="submit"]{
          @apply self-end px-[70px] mt-[20px];
        }
      }
    }

    h2 {
      @apply text-2xl pt-[49px] md:pt-[53px] md:text-[32px] font-normal self-start text-center md:text-left;
    }

    aside.info-section {
      @apply flex flex-col w-full lg:w-[47%] bg-cover bg-center place-content-center bg-no-repeat h-[100vh];
      background-image: url('../assets/login/bg-home-right.jpg');

      .title{
        @apply text-[32px] font-normal text-center;
      }

      .subtitle{
        @apply text-base mt-2 font-normal text-center;
      }

      a{
        @apply mt-[72px] self-center;
      }
    }
  }
</style>
