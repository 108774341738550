import requests from '@/services/requests';
import response from '@/services/response';

const estado = {
  misMonturas: null,
  misMonturasDetalle: null,
  misMarcas: null,
  misModelos: null,
  misTipoMontura: null,
};

const getters = {
  misMonturas: (state) => state.misMonturas,
  misMonturasDetalle: (state) => state.misMonturasDetalle,
};

const actions = {
  async GuardaForma({ rootState }, { forma }) {
    const data = await requests.guardarForma(
      rootState.auth.token,
      json2xml(forma),
    );
    const postData = response.formas(data);
    return postData;
  },
  async BuscarForma({ rootState, commit }) {
    const data = await requests.buscarForma(
      rootState.auth.token,
      rootState.indo.profile.AreaVenta.CLIENTESAP
    );

    const postData = response.buscarFormas(data);
 console.log(postData);
    await commit('setMisMonturas', postData.oClaseBAPI.listFORMAS_CTE_LIST);
    await commit('setMisMonturasDetalle', postData.oClaseBAPI.listFORMAS_CTE);

    return postData.oClaseBAPI;
  },
  GetMisMarcas({ state }) {
    return state.misMarcas;
  },
  GetMisModelo({ state }, { marca, modelo }) {
    return state.misMonturasDetalle.find(m => m.MARCA === marca && m.ZMONTURA_T === modelo);
  },
  GetMisModelos({ state }, { marca }) {
    return state.misMonturas.find(m => m.MARCA === marca);
  },
  GetMisTipoMontura({ state }, { marca, modelo }) {
    return state.misTipoMontura(marca, modelo);
  },
};

const mutations = {
  setMisMonturas(state, data) {
    state.misMonturas = data;
    state.misMarcas = [...new Set(data.map((montura) => montura.MARCA))].sort((a, b) => a.localeCompare(b));
  },
  setMisMonturasDetalle(state, data) {
    state.misMonturasDetalle = data;
    state.misTipoMontura = (marca, modelo) => [...new Set(state.misMonturasDetalle
      .filter(montura => montura.MARCA === marca && montura.ZMONTURA_T == modelo)
      .map(montura => montura.TIPO_MONTU))
    ];
  },
  setMisMarcas(state, data) {
    state.misMarcas = data;
  },
  setMisModelos(state, data) {
    state.misModelos = data;
  },
};

export default {
  state: estado,
  getters,
  actions,
  mutations,
};
