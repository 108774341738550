<template>
    <RememberPassword />
</template>

<script>
  import RememberPassword from '@/components/sections/password/RememberPassword.vue';

  export default {
    name: 'RememberPasswordView',
    components: {
        RememberPassword,
  },
  };
</script>
